/**
 *
 */
const initFaviconChanger = () =>
{
	const dataElem = document.querySelector( '#afwd-staging-migration-data' );

	if (dataElem instanceof HTMLElement) {

		const data = JSON.parse( dataElem.textContent );

		if (data.isStagingSite) {

			getFavItems().then( favItems =>
			{
				favItems.forEach( item =>
				{
					const w   = item.canvas.width;
					const h   = item.canvas.height;
					const ctx = item.ctx;

					// defaults
					ctx.strokeStyle = '#e18746';
					ctx.lineWidth   = w / 6;

					// image
					ctx.drawImage( item.img, 0, 0 );

					// border
					ctx.strokeRect( 0, 0, w, h );

					// diagonal line
					ctx.lineWidth = ctx.lineWidth / 2;
					ctx.beginPath();
					ctx.moveTo( 0, 0 );
					ctx.lineTo( w, h );
					ctx.closePath();
					ctx.stroke();

					item.favicon.href = item.canvas.toDataURL();

					item.canvas.remove();
				} );
			} );
		}
	}
};


/**
 *
 * @return {Promise<FaviconItem[]>}
 */
const getFavItems = () =>
{
	const favicons = document.querySelectorAll( 'link[rel="icon"][sizes]' );
	const promises = [...favicons].map( favicon =>
	{
		return new Promise( resolve =>
		{
			const canvas                        = document.createElement( 'canvas' );
			const ctx                           = canvas.getContext( '2d' );
			const [faviconWidth, faviconHeight] = favicon
				.getAttribute( 'sizes' )
				.split( 'x' )
				.map( s => parseInt( s, 10 ) );
			const img                           = document.createElement( 'img' );

			img.addEventListener( 'load', () =>
			{
				canvas.width         = img.width;
				canvas.height        = img.height;
				canvas.style.display = 'none';

				document.body.append( canvas );

				resolve( {
					faviconWidth:  faviconWidth,
					faviconHeight: faviconHeight,
					favicon:       favicon,
					img:           img,
					canvas:        canvas,
					ctx:           ctx,
				} );
			} );

			img.src = favicon.href;
		} );
	} );

	return Promise.all( promises );
};


/**
 *
 */
export default initFaviconChanger;